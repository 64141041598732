<template>
  <div id="UsuarioCadastro">
      <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

      <!-- TITULO DA PAGINA -->
      <v-toolbar-title class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
        <router-link
        :to="'/usuarioconsulta'"
        class="nav-link"
        aria-current="page"
        >
          <v-btn icon dark color="grey" class="btn-icon mr-1">
            <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
          </v-btn>
        </router-link> 
        Cadastro de Usuários
      </v-toolbar-title>
      <!-- FIM TITULO DA PAGINA -->

        <v-toolbar-title
          class="mt-0 mb-15 ml-4 pa-0 d-flex flex-column w-100"
          dark
          elevation="0"
        >
          <div class="d-flex align-center w-100" tabindex="-1">
          </div>
        </v-toolbar-title>

        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>
    
    <!-- Help -->
    <v-expansion-panels
      class="pa-4 pt-0 px-0 expansion-panel d-none"
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Fim Help -->

    <v-row class="mt-n11 w-100 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 w-100">
        <v-container class="container-table container-rounded px-0 w-100 mx-0">

        <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
        <v-dialog
          v-if="dialog_crop"
          :value="true"
          persistent
          style="width: 330"
          :max-width="330"
          :retain-focus="false"
        >
          <v-card>
            <!-- <v-card-title>Foto</v-card-title> -->
            <v-card-text class="pt-7">
              <VueCropImage
                :img="dados.foto"
                :imgsync.sync="dados.foto"
                :dialog_crop.sync="dialog_crop"
                ref="VueCropImage"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- --------------------------------------------------- -->

        <!-- Conteiner Principal  -->
          <!-- Form Cadastro -->
          <v-row class="w-100">
            <v-col cols="12" sm="6" class="pr-5">

              <h4 class="h5 font-regular-bold">Dados Pessoais</h4>

              <v-form
                class="mt-5"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <div class="d-flex justify-center">
                  <v-avatar
                    size="100px"
                    v-ripple
                    v-if="!dados.foto"
                    class="grey lighten-2 mb-6"
                  >
                    <span v-on:click="dialog_crop = true"
                      >Clique p/ adicionar avatar
                    </span>
                  </v-avatar>
                  <v-avatar size="100px" v-ripple v-else class="mb-3">
                    <img
                      :src="dados.foto"
                      alt="avatar"
                      v-on:click="dialog_crop = true"
                    />
                  </v-avatar>
                </div>

                <div
                  class="container-privileges d-flex justify-space-between mt-5"
                >
                  <div class="d-flex flex-column">
                    <p class="mt-2 font-weight-bold body-1">
                      {{ dados.situacao }}
                    </p>
                    <p class="mb-2">Ativo para fazer login no sistema</p>
                    <p class="mb-2">ou Pendente</p>
                  </div>
                  <v-switch
                    class="switch-privileges"
                    v-model="dados.situacao"
                    false-value="Pendente"
                    true-value="Ativo"
                    color="green"
                    inset
                  ></v-switch>
                </div>

                <v-text-field
                  v-model="dados.nome"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  autofocus
                  label="Nome"
                  placeholder="Nome"
                  filled
                  required
                  dense
                  :rules="nameRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.cpf"
                  v-mask="MASK_CPFCNPJ"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="CPF/CNPJ"
                  placeholder="CPF/CNPJ"
                  filled
                  :rules="cpfRules"
                  required
                  dense
                ></v-text-field>
                <!-- v-mask="`${ maskCPFCNPJ }`" -->

                <v-text-field
                  v-model="dados.email"
                  class="mb-0"
                  light
                  loader-height="1"
                  hint="Por exemplo: meunome@meuservidor.com.br"
                  background-color="#FFF"
                  label="Email"
                  placeholder="Digite o seu email"
                  filled
                  maxlength="200"
                  :rules="emailRules"
                  required
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="dados.celular"
                  v-mask="MASK_TELEFONE"
                  class="mb-0"
                  background-color="#FFF"
                  label="Celular"
                  placeholder="(00)00000-0000"
                  filled
                  :rules="foneRules"
                  required
                  dense
                ></v-text-field>

                <!-- Pertencendte ao parceiro -->
                <v-autocomplete
                  v-model="usuario_parceiro_selecionado"
                  :items="usuariosParceiros"
                  item-text="nome"
                  item-value="cod_usuario"
                  label=" Sou corretor do Parceiro "
                  background-color="#FFFFFF"
                  filled
                  return-object
                  required
                  outlined
                  dense
                  style="max-width:500px;"
                >
                <template v-slot:selection="data">
                  <v-avatar color="#C9C9C9" class="mt-1 mr-3">
                    <v-avatar>
                      <v-img
                        v-if="usuario_parceiro_selecionado.foto"
                        :src="usuario_parceiro_selecionado.foto">
                      </v-img>
                      <span 
                        v-else
                        class="iniciais">
                        {{ nomeIniciais(usuario_parceiro_selecionado.nome) }}
                      </span>
                    </v-avatar>
                  </v-avatar>
                  {{ data.item.nome }}
                </template>

                  <template v-slot:item="data">
                    <template>
                      <v-avatar color="#C9C9C9" class="mt-1 mr-3">
                    <v-avatar>
                      <v-img
                        v-if="data.item.foto"
                        :src="data.item.foto">
                      </v-img>
                      <span 
                        v-else
                        class="iniciais">
                        {{ nomeIniciais(data.item.nome) }}
                      </span>
                    </v-avatar>
                  </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.nome"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

              </v-form>
              <!-- Fim Form Cadastro -->
            </v-col>
            <!-- v-mask="`${ maskTELEFONE }`" -->

            <v-col cols="12" sm="6" class="pl-5 pt-5">
              <h4 class="h5">Privilégios de acesso</h4>

              <div
                v-for="(elem, index) of dados.usuario_funcao"
                v-bind:key="index"
              >
                <!-- switch -->
                <div
                  class="
                    container-privileges
                    d-flex
                    flex-column
                    justify-space-between
                    mt-8
                    mb-4
                  "
                >
                  <div class="d-flex justify-space-between">
                    <div class="d-flex justify-start flex-column">
                      <p class="mt-2 font-weight-bold body-1">
                        {{ elem.funcao_nome }}
                      </p>
                      <p class="mb-2">{{ elem.funcao_descricao }}</p>
                    </div>
                    <div class="d-flex justify-end">
                      <v-switch
                        class="switch-privileges"
                        v-model="elem.acesso"
                        inset
                      ></v-switch>
                    </div>
                  </div>

                  <!-- Funcao Corretor  -->
                  <div
                    v-if="elem.cod_funcao == 3 && elem.acesso"
                    class="
                      container-privileges
                      d-flex
                      flex-column
                      justify-space-between
                      mt-8
                      mb-4
                      mr-3
                    "
                    style="border: 1px solid #DDDDDD;"
                  >
                    <p class="mt-2 font-weight-bold body-1">
                        Possui acesso aos seguintes empreendimentos
                    </p>
                    <!-- TABELA -------------------------------------------------------->
                    <v-data-table
                      v-model="selected"
                      :items="dados.empreend_corretor"
                      single-select
                      show-select
                      hide-default-header
                      hide-default-footer
                      height="300px"
                      top="0px"
                      :items-per-page="-1"
                    >
                      <template v-slot:body="{ items }">
                        <tbody >
                          <tr 
                            class="row-table-user"
                            v-for="item in items"
                            :key="item.cod_empreendimento"
                          >
                            <td width="10%">
                                <v-checkbox
                                class="mt-n1"
                                v-model="item.acesso"
                                :key="item.cod_empreendimento"
                                hide-details
                                />
                            </td>
                            <!-- v-on:change="seleciona($event, item)" -->

                            <td class="hide-sm py-">
                                {{ item.empreend_nome }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                    <!-- FIM TABELA -------------------------------------------------------->
                  </div>

                  <!-- Funcao Gestor  -->
                  <div
                    v-if="elem.cod_funcao == 4 && elem.acesso"
                    class="
                      container-privileges
                      d-flex
                      flex-column
                      justify-space-between
                      mt-8
                      mb-4
                      mr-3
                    "
                    style="border: 1px solid #DDDDDD;"
                  >
                    <p class="mt-2 font-weight-bold body-1">
                        Possui acesso aos seguintes empreendimentos
                    </p>
                    <!-- TABELA -------------------------------------------------------->
                    <v-data-table
                      v-model="selected"
                      :items="dados.empreend_gestor"
                      single-select
                      show-select
                      hide-default-header
                      hide-default-footer
                      height="300px"
                      top="0px"
                      :items-per-page="-1"
                    >
                      <template v-slot:body="{ items }">
                        <tbody >
                          <tr 
                            class="row-table-user"
                            v-for="item in items"
                            :key="item.cod_empreendimento"
                          >
                            <td width="10%">
                                <v-checkbox
                                class="mt-n1"
                                v-model="item.acesso"
                                :key="item.cod_empreendimento"
                                hide-details
                                />
                            </td>
                            <!-- v-on:change="seleciona($event, item)" -->

                            <td class="hide-sm py-">
                                {{ item.empreend_nome }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                    <!-- FIM TABELA -------------------------------------------------------->
                  </div>

                </div>
              </div>

            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card color="transparent" elevation="0" class="d-flex justify-center mt-3 mb-3">
            <router-link
              :to="{ name: 'UsuarioConsulta' }"
              class="nav-link"
              aria-current="page"
            >
              <v-btn
                id="btn_cancelar"
                ref="btn_cancelar"
                class="mr-4"
                color="primary"
                text
              >
                Cancelar
              </v-btn>
            </router-link>

            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading"
              v-on:click="validate()"
            >
              Salvar
            </v-btn>
          </v-card>

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <v-alert
      :value="alert"
      v-on:click="fecha_alert()"
      color="red"
      dark
      icon="mdi-home"
      transition="scale-transition"
      elevation="1"
      type="warning"
      dense
    >
      {{ alert_msg }}
    </v-alert>
    <!-- <Footer /> -->

  </div>
</template>

<script>
  import store_Usuarios from "./store_Usuarios";
  import store_site from "../../store/store_site";
  import {
    nomeIniciais,
    CpfCnpjIsValid,
    emailIsValid,
    telefoneIsValid,
  } from "../../services/funcoes";
  import { COR_PRINCIPAL, MASK_CPFCNPJ, MASK_TELEFONE } from "../../services/constantes";
  import { UsuarioPut, UsuarioPost } from "../../services/autenticacao";
  import { FuncoesGet } from "../../services/global";
  import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";
  const FileType = require("file-type");

  export default {
    name: "UsuarioCadastro",

    components: {
      VueCropImage,
    },

    data() {
      return {

        store_Usuarios: store_Usuarios,
        store_site: store_site,

        usuariosParceiros: [],
        usuario_parceiro_selecionado   : {},
        nomeIniciais      : nomeIniciais,

        empreendimentosTeste: [
                              {cod_empreendimento: 32, empreend_nome: "Residencial Reserva dos Ipês", privilegio_acesso: true },
                              { cod_empreendimento: 26, empreend_nome: "Jardim Santa Lúcia", privilegio_acesso: false },
        ],
        
        isOpen: false,

        selected  : [],
        checked   : false,

        img: null,
        imgsync: null,
        dialog_crop: false,
        dialog_webcam: false,
        loading : false,


        MASK_CPFCNPJ      : MASK_CPFCNPJ,
        MASK_TELEFONE     : MASK_TELEFONE,
        COR_PRINCIPAL     : COR_PRINCIPAL,

        /* Campos */
        acao: "E", // E=Edicao, I=Inclusão
        valid: true,
        alert: false,
        alert_msg: "",

        // stencilCoordinates: { width: 200, height: 200 },

        dados: {
          cod_usuario: null,
          nome: "",
          cpf: "",
          email: "",
          celular: "",
          nascimento_dt: null,
          profissao: null,
          estado_civil: null,
          situacao: null,
          foto: null,
          usuario_funcao: [],
        },

        /* Formulário Reprovação */
        form: false,
        nameRules: [
          (value) => !!value || "O nome é obrigatório",
          (value) =>
            (value && value.length >= 3) ||
            "O nome deve ter no mínimo 3 caracteres",
          (value) =>
            (value && value.length <= 60) ||
            "O nome deve ter menos do que 60 caracteres",
        ],
        emailRules: [
          (value) => !!value || "O e-mail é obrigatório",
          (value) => (value && emailIsValid(value)) || "O e-mail não é válido",
        ],
        foneRules: [
          (value) => !!value || "O celular é obrigatório",
          (value) =>
            (value && telefoneIsValid(value)) || "O celular não é válido",
        ],
        cpfRules: [
          (value) => !!value || "O CPF é obrigatório",
          (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
        ],
      };
    },

    watch: {
      // dialog_crop() {
      //   console.log('MUDOU');
      // },
      // imgSrc() {
      //   console.log('MUDOU ImgScr');
      // }
    },

    async mounted() {
      var lo_params = { cod_usuario: this.$route.params.cod_usuario };

      // Edição do cadastro -----------------------------------------
      if (lo_params.cod_usuario) {
        var lo_Res = await this.store_Usuarios.UsuariosGet(lo_params);
        if (lo_Res)
          this.dados = { ...lo_Res };
        this.acao = "E";
        //console.log(this.dados)
      }
      // Inclusão de novo usuário -----------------------------------
      else {
        var lo_Res = await FuncoesGet();
        if (lo_Res)
          lo_Res.map((value) => {
            this.dados.usuario_funcao.push({
              cod_funcao: value.cod_funcao,
              acesso: false,
              funcao_descricao: value.funcao_descricao,
              funcao_nome: value.funcao_nome,
            });
          });
        this.acao = "I";
      }

      // Busca os Usuarios Parceiros
      await this.busca_UsuariosParceiros();
      await this.busca_UsuarioParceiroSelecionado(this.dados.cod_parceiro);
      console.log('mounted', this.dados);

    },

    methods: {

      async busca_UsuariosParceiros() {
        this.usuariosParceiros = await this.store_Usuarios.UsuariosParceirosGet();
      },

      async busca_UsuarioParceiroSelecionado(cod_parceiro) {
        if (cod_parceiro) {
          console.log('this.usuario_parceiro_selecionado 1',this.usuariosParceiros);
          this.usuario_parceiro_selecionado = this.usuariosParceiros.find(o => (o.cod_usuario == cod_parceiro))
          console.log('this.usuario_parceiro_selecionado', this.usuario_parceiro_selecionado);
        }
      },

      startCameraStream() {
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        });
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.$refs.camera.srcObject = stream;
          })
          .catch((error) => {
            console.log(
              "Browser doesn't support or there is some errors." + error
            );
          });
      },

      async getDevices() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          //console.log("NAO")
          return false;
        }
        try {
          let allDevices = await navigator.mediaDevices.enumerateDevices();
          //console.log("SIM", allDevices)
          for (let mediaDevice of allDevices) {
            if (mediaDevice.kind === "videoinput") {
              let option = {};
              option.text = mediaDevice.label;
              option.value = mediaDevice.deviceId;
              this.options.push(option);
              this.devices.push(mediaDevice);
            }
          }
          return true;
        } catch (err) {
          throw err;
        }
      },

      // --------------------------------------------------------------------------
      // --------------------------------------------------------------------------
      // --------------------------------------------------------------------------
      abre() {
        if (!this.dados.foto) {
          this.$refs.file_new.click();
        } else {
          this.$refs.file_update.click();
        }
      },

      fecha_alert() {
        this.alert = false;
      },

      /* Método que recebe arquivo de imagem */
      handleFile({ target }) {
        this.dialog_crop = true;

        // let reader = new FileReader();
        // if (target.files && target.files.length > 0) {
        //   reader.readAsDataURL(target.files[0]);
        //   reader.onload = async (file) => {
        //     this.dados.foto = file.target.result
        //     this.dialog_crop = true;
        //     //console.log("this.$refs.vue_crop_image", this.$refs);
        //   }
        // }
      },

      async validate() {
        const lb_valido = this.$refs.form.validate();
        this.loading = true

        if (lb_valido) {
          this.dados.cod_parceiro = this.usuario_parceiro_selecionado?.cod_usuario ?? null;
          var lo_JSON = { ...this.dados, funcoes: [] };

          this.dados?.usuario_funcao?.map((value) => {
            if (value.acesso)
              lo_JSON.funcoes.push({ cod_funcao: value.cod_funcao });
          });
          // Editando usuário ----------------
          var ls_form;
          if (this.acao == "E") {
            ls_form = await UsuarioPut(lo_JSON);
          } else { // Incluindo usuário ----------------
            ls_form = await UsuarioPost(lo_JSON);
          }

          if (ls_form.result.message == "success") {
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_form.result.result.trim();
            this.store_site.alert           = true;


          } else {
            this.store_site.alert_cor       = "#A00000";
            this.store_site.alert_timeout   = 20000;
            this.store_site.alert_msg       = ls_form.errors.trim();
            this.store_site.alert           = true;
          }
          this.loading = false
        }
      },
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
    },
  };
</script>

<style scoped>

  .main-title {
    background: #0548a3;
  }

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .container-principal {
    max-width: calc(100% - 32px) !important;
    margin-bottom: 20px !important;
  }

  .container-rounded {
    max-width: 100%;
    background: #F2F6F7 !important;
    border-radius: 12px !important;
    margin: auto !important;
    margin-bottom: 20px !important;
    padding: 20px 13px 13px 13px !important;
  }

  /* ======================== AVATAR UPLOAD ===================== */
  .mx-auto {
    margin: auto;
  }

   .input-file {
    width: 110px !important;
    height: 35px !important;
    border-radius: 50%;
    position: absolute !important;
    opacity: 0 !important;
    cursor: pointer !important;
    outline: none !important;
    z-index: 2;
  }

  .avatar-container__file {
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    outline: none;
  }

  /* ======================== PRIVILEGES ===================== */
  .h5 {
    font-family: "Montserrat", sans-serif;
    color: rgba(0, 0, 0, 0.87);
  }

  .h5::after {
    display: block;
    width: 50px;
    height: 3px;
    margin-top: 0.2rem;
    content: "";
    background: #274abb;
  }

  .container-privileges {
    margin-bottom: 24px;
    padding-left: 12px;
    border-radius: 8px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }

  .btn {
    width: 120px;
  }

  .v-btn {
    display: inline-block;
  }

  /* CELULAR */
  @media (max-width: 599px) {

    .container,
    .container-principal {
      margin: 0px !important;
      padding: 0px 8px !important;
    }

    .container-rounded {
      background: #F2F6F7 !important;
      border-radius: 12px !important;
      max-width: calc(100%) !important;
      margin: auto !important;
      padding: 20px 13px 13px 13px !important;
    }

    .title-page {
      margin-left: 16px !important;
    }

    .theme--light.v-data-table {
      border-radius: 12px 12px 0px 0px !important;
    }

    .avatar {
      margin: 0px 0px 0px 4px !important;
    }
    
    .status-mobile {
      display: block;
    }

    .btn-icon {
      margin-right: 0px !important;
    }
    .search-input {
      margin: 0.8rem 0 1.2rem 0;
      min-width: auto;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      height: 35px !important;
      /* min-width: calc(100% - 15px); */
    }
    .search-input--outlined {
      background-color: #FF0000;
    }
  }

</style>